$(document).ready(function () {

    (function ($) {
        $.extend($.summernote.lang, {
            'es-ES': {
                font: {
                    bold: 'Negrita',
                    italic: 'Itálica',
                    underline: 'Subrayado',
                    strike: 'Tachado',
                    clear: 'Quitar estilo de fuente',
                    height: 'Altura de la línea',
                    size: 'Tamaño de la fuente',
                    name: 'Selecionar tipo de fuente',
                    sizeunit: "Unidad de tamaño de fuente"
                },
                image: {
                    image: 'Imagen',
                    insert: 'Insertar imagen',
                    resizeFull: 'Redimensionar a tamaño completo',
                    resizeHalf: 'Redimensionar a la mitad',
                    resizeQuarter: 'Redimensionar a un cuarto',
                    floatLeft: 'Flotar a la izquierda',
                    floatRight: 'Flotar a la derecha',
                    floatNone: 'No flotar',
                    dragImageHere: 'Arrastre una imagen aquó',
                    selectFromFiles: 'Seleccionar a partir de un archivo',
                    url: 'URL de la imagen'
                },
                link: {
                    link: 'Link',
                    insert: 'Insertar enlace',
                    unlink: 'Quitar enlace',
                    edit: 'Editar',
                    textToDisplay: 'Texto para mostrar',
                    url: 'Hacia que URL lleva el enlace?',
                    openInNewWindow: 'Abrir en una nueva ventana',
                    useProtocol: 'Usar el protocolo predefinido',
                },
                video: {
                    video: 'Video',
                    videoLink: 'Link para el video',
                    insert: 'Insertar video',
                    url: 'URL del video?',
                    providers: '(YouTube, Vimeo, Vine, Instagram, o DailyMotion)'
                },
                table: {
                    table: 'Tabla'
                },
                hr: {
                    insert: 'Insertar línea horizontal'
                },
                style: {
                    style: 'Estilo',
                    normal: 'Normal',
                    blockquote: 'Cita',
                    pre: 'Código',
                    h1: 'Título 1',
                    h2: 'Título 2',
                    h3: 'Título 3',
                    h4: 'Título 4',
                    h5: 'Título 5',
                    h6: 'Título 6'
                },
                lists: {
                    unordered: 'Lista con marcadores',
                    ordered: 'Lista numerada'
                },
                options: {
                    help: 'Ayuda',
                    fullscreen: 'Pantalla completa',
                    codeview: 'Ver código fuente'
                },
                paragraph: {
                    paragraph: 'Párrafo',
                    outdent: 'Menos tabulación',
                    indent: 'Más tabulación',
                    left: 'Alinear a la izquierda',
                    center: 'Alinear al centro',
                    right: 'Alinear a la derecha',
                    justify: 'Justificar'
                },
                color: {
                    recent: 'Color de fondo',
                    more: 'Más colores',
                    background: 'Fondo',
                    foreground: 'Fuente',
                    transparent: 'Transparente',
                    setTransparent: 'Fondo transparente',
                    reset: 'Restaurar',
                    resetToDefault: 'Restaurar por defecto'
                },
                shortcut: {
                    shortcuts: 'Atajos de teclado',
                    close: 'Cerrar',
                    textFormatting: 'Formato de texto',
                    action: 'Acción',
                    paragraphFormatting: 'Formatao de párrafo',
                    documentStyle: 'Estilo de documento'
                },
                history: {
                    undo: 'Deshacer',
                    redo: 'Rehacer'
                }
            }
        });
    })(jQuery);
    (function (factory) { if (typeof define === "function" && define.amd) { define(["jquery"], factory) } else if (typeof module === "object" && module.exports) { module.exports = factory(require("jquery")) } else { factory(window.jQuery) } })(function ($) {
        $.extend($.summernote.options, { removeTags: ["style", "script", "applet", "embed", "param", "noframes", "noscript", "font", "span", "iframe", "form", "input", "button", "select", "option", "colgroup", "col", "std"], removeAttributes: ["rel", "class", "style"], wildCardAttributesPrefix: ["data-"], msgStyle: "position:absolute;top:0;left:0;right:0", msgShow: false, msgTxt: "Your pasted text has been cleaned." });
        $.extend($.summernote.plugins, {
            codeCleaner: function (context) {
                var $note = context.layoutInfo.note;
                var $editor = context.layoutInfo.editor;
                var $options = context.options;
                $note.on("summernote.paste", function (e, evt) {
                    //evt.preventDefault();
                    // var text = evt.originalEvent.clipboardData.getData("text/plain"),
                    //     html = evt.originalEvent.clipboardData.getData("text/html");
                    // if (html) {
                    //     var tagStripper = new RegExp("<[ /]*(" + $options.removeTags.join("|") + ")[^>]*>", "gi"),
                    //         attributeStripper = new RegExp(" (" + $options.removeAttributes.join("|") + ")(=\"[^\"]*\"|='[^']*'|=[^ ]+)?", "gi"),
                    //         tS = new RegExp("<(/)*(meta|link|\\?xml:|st1:|o:|font)(.*?)>", "gi"),
                    //         commentStripper = new RegExp("\x3c!--(.*)--\x3e", "g");
                    //     html = html.toString().replace(tS, "").replace(commentStripper, "").replace(tagStripper, "").replace(attributeStripper, " ")
                    // }
                    // var wCA = $options.wildCardAttributesPrefix;
                    // for (var i = 0; i < wCA.length; i++) {
                    //     var aS = new RegExp(" " + wCA[i] + "(.*?)=['|\"](.*?)['|\"]", "gi");
                    //     html = html.toString().replace(aS, "")
                    // }
                    //var $html = $("<span/>").html(html || text);
                    //$note.summernote("insertNode", $html[0]);
                    if ($options.msgShow) { $editor.find(".note-status-output").html('<div class="alert alert-success">' + $options.msgTxt + "</div>") }

                    return false
                });
                $note.on("summernote.keydown", function () { if ($editor.find(".note-status-output").html().length > 0) { $editor.find(".note-status-output").html("") } })
            }
        })
    });
});